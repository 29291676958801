import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import isNotBlogPost from "../components/utility"

class AuthorRoute extends React.Component {
  render() {
    const blogPosts = this.props.data.allMarkdownRemark.edges
    const postLinks = blogPosts.map((post) => (
      <li key={post.node.frontmatter.slug}>
        <Link to={isNotBlogPost(post.node.frontmatter.slug)}>
          <h2 className="is-size-2">{post.node.frontmatter.title}</h2>
        </Link>
      </li>
    ))
    const author = this.props.pageContext.author
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const authorHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } written by “${author}”`

    return (
      <Layout>
        
          <Helmet title={`${author} | ${title}`} />
          <div className="container">            
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >
                <section className="section">
                <h3 className="title is-size-4 is-bold-light">{authorHeader}</h3>
                <ul className="taglist content">{postLinks}</ul>
                <p>
                  <Link to="/author/">Browse all authors</Link>
                </p>
                </section>
              </div>
            {/* </div> */}
          </div>
        
      </Layout>
    )
  }
}

export default AuthorRoute

export const authorPageQuery = graphql`
  query AuthorPage($author: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { author: { in: [$author] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`
